import React from 'react';
import { Link } from 'gatsby';
import ParsedDate from '../date';

import './style.scss';


const Teaser = ({post, people}) => {
  if(!people) {

  return (
  
    <Link className="teaserWithImg" to={`/content-hub/${post.slug}`} >
      <div className="teaserImgContainer">
        <img className="teaserImg" alt="" src={post.teaserImage ? post.teaserImage.resolutions.src : null} />
        <div className="teaserCategory">{post.category ? post.category : null}</div>
      </div>
        <div className="teaserContent linkTeaserContent">
          <h3>{post.title}</h3>
          <p>{post.excerpt ? post.excerpt.excerpt : null}</p>
          <div>{post.showDate && post.date ? <ParsedDate date={post.date} />: null}</div>
       
        </div>
    </Link>

  );} else {
    return (
      
      <div className="teaserWithImg" >
        <div className="teaserImgContainer">
          <img className="teaserImg" alt="" src={post.teaserImage ? post.teaserImage.resolutions.src : null} />
          <div className="teaserCategory">{people ? post.peopleCategoryLabel : null}</div>
        </div>
          <div className="teaserContent">
            <h3>{post.title}</h3>
            <p>
              {people && post.jobTitle ? post.jobTitle : null}<br />
              {people && post.unit ? post.unit : null}<br />
              {people && post.phone ? post.phone : null}<br />
              {people && post.email ? post.email : null}
            </p>
            <div className="personSomeButtons">
              {people && post.twitter ? <a href={post.twitter} target="_blank" rel="noreferrer noopener">
                <img className="someIcon" src="/twitter.svg" alt={`Twitter of ${post.title}`} />
              </a>: null}
              {people && post.linkedin ? <a href={post.linkedin} target="_blank" rel="noreferrer noopener">
                <img className="someIcon" src="/linkedin.svg" alt={`Linkedin of ${post.title}`} />
              </a>: null}
            </div>
          </div>
      </div>);
  }
}
export default Teaser;